import { useState, useEffect, FormEvent, KeyboardEvent } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import Swal from "sweetalert2";
import "react-phone-input-2/lib/bootstrap.css";
import { Box, Typography } from "@mui/material";
import imgSrc from "./images/bear.png";
import { getCountryCode } from "utils/common";
import Page from "components/Page";
import Button from "components/Button";
import Loading from "components/Loading";
import useAuth from "context/useAuth";

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, signin } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("us");

  const { state } = location as any;
  let from = state?.from?.pathname || "/";

  if (state?.from?.search) {
    from = from + state.from.search;
  }

  useEffect(() => {
    if (isLoggedIn) {
      console.log("Logged in, redirecting...");
      navigate(from, { replace: true });
      return;
    }

    const setOnMount = async () => {
      const code = await getCountryCode();
      setCountryCode(code.toLowerCase());
    };

    setOnMount();
  }, [setCountryCode, isLoggedIn]);

  const handleLogin = async (
    e: FormEvent<HTMLFormElement> | KeyboardEvent<HTMLInputElement>
  ) => {
    e.preventDefault();

    if (!phoneNumber) {
      Swal.fire({
        title: "Phone number required",
        icon: "warning",
        showConfirmButton: true,
        timer: 3000,
      });
      return;
    }

    try {
      setIsLoading(true);
      await signin(phoneNumber, null, () => navigate(from, { replace: true }));
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) return <Loading />;

  return (
    <Page showLogo>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          sx={{
            width: 180,
            height: 180,
            objectFit: "cover",
          }}
          src={imgSrc}
          alt="Bear"
        />
        <Typography
          component="span"
          variant="h4"
          alignSelf="flex-start"
          fontWeight={900}
          m="30px 0 10px"
        >
          Welcome back!
        </Typography>
        <Typography
          component="span"
          variant="subtitle1"
          alignSelf="flex-start"
          mt={1}
        >
          Just to verify it&apos;s you, we will send you <br /> a{" "}
          <strong>one time password</strong> via SMS:
        </Typography>
        <Box
          component="form"
          onSubmit={handleLogin}
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box height={50} />
          <Typography
            sx={{
              fontSize: "16px",
              position: "absolute",
              top: 35,
              left: 0,
            }}
            component="span"
          >
            Phone Number
          </Typography>
          <PhoneInput
            enableSearch
            country={countryCode}
            specialLabel="Phone Number"
            placeholder="+1-234-567-8910"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleLogin(e);
              }
            }}
            inputStyle={{
              backgroundColor: "transparent",
              borderRadius: 0,
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              boxShadow: "none",
            }}
          />
          <Box height={70} />
          <Button type="submit">
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "20px",
              }}
              component="span"
            >
              Send Code
            </Typography>
          </Button>
        </Box>
      </Box>
    </Page>
  );
}
