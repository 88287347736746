import {
  useState,
  useEffect,
  ChangeEvent,
  FormEvent,
  KeyboardEvent,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import Swal from "sweetalert2";
import "react-phone-input-2/lib/bootstrap.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { getCountryCode } from "utils/common";
import { ReactComponent as Multeez } from "assets/multeez.svg";
import Page from "components/Page";
import Button from "components/Button";
import Input from "components/Input";
import Loading from "components/Loading";
import useAuth from "context/useAuth";

export default function Signup() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, signin } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [isSignupFired, setIsSignupFired] = useState(false);
  const [countryCode, setCountryCode] = useState("us");

  const { state } = location as any;
  let from = state?.from?.pathname || "/";

  if (state?.from?.search) {
    from = from + state.from.search;
  }

  useEffect(() => {
    if (isLoggedIn && !isSignupFired) {
      console.log("Logged in, redirecting...");
      navigate(from, { replace: true });
      return;
    }

    const setOnMount = async () => {
      const code = await getCountryCode();
      setCountryCode(code.toLowerCase());
    };

    setOnMount();
  }, [setCountryCode, isLoggedIn]);

  const handleSignup = async (
    e: FormEvent<HTMLFormElement> | KeyboardEvent<HTMLInputElement>
  ) => {
    e.preventDefault();

    if (!(email && phoneNumber)) {
      Swal.fire({
        title: "Email and phone number required",
        icon: "warning",
        showConfirmButton: true,
        timer: 3000,
      });
      return;
    }

    try {
      setIsSignupFired(true);
      setIsLoading(true);
      await signin(phoneNumber, email, () =>
        navigate("/onboarding", { replace: true })
      );
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) return <Loading />;

  return (
    <Page showLogo>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Multeez width="90px" />
        <Typography component="span" variant="subtitle1" mt={1}>
          Private Alpha
        </Typography>
        <Typography
          component="span"
          variant="h4"
          fontWeight={900}
          m="60px 0 20px"
        >
          Create Account
        </Typography>
        <Box
          component="form"
          onSubmit={handleSignup}
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Input
            required
            type="email"
            name="email"
            label="Email"
            variant="standard"
            placeholder="your@email.com"
            height={70}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
          />
          <Box height={30} />
          <Typography
            sx={{
              fontSize: "12px",
              position: "absolute",
              top: 95,
              left: 0,
            }}
            component="span"
          >
            Phone Number
          </Typography>
          <PhoneInput
            enableSearch
            country={countryCode}
            specialLabel="Phone Number"
            placeholder="+1-234-567-8910"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSignup(e);
              }
            }}
            inputStyle={{
              backgroundColor: "transparent",
              borderRadius: 0,
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              boxShadow: "none",
            }}
          />
          <Box height={50} />
          <Button type="submit">
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "20px",
              }}
              component="span"
            >
              Sign up
            </Typography>
          </Button>
        </Box>
        <Typography component="p" variant="subtitle2" color="#566665" mt={2}>
          By joining, you agree to our{" "}
          <Box
            sx={{
              textDecoration: "underline",
            }}
            component="a"
            href="https://multeez.com/wp-content/uploads/2021/12/Multeez-Terms-of-Use.pdf-Nov-28-202112040250.5.docx.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms
          </Box>{" "}
          &{" "}
          <Box
            sx={{
              textDecoration: "underline",
            }}
            component="a"
            href="https://multeez.com/wp-content/uploads/2021/12/Multeez-Privacy-Policy-Dec-08-2021-12057059.2.docx.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy policy
          </Box>
        </Typography>
      </Box>
    </Page>
  );
}
