import { Axios } from "axios";
import { WETH9Address } from "../../../contract/contract";
import { logError } from "../../../utils/logger";
import { RaribleOrder, RaribleOrderTake, EncodedOrder } from "../types";
import rarible from "../client";

const ERC721 = "ERC721";
const ERC721LAZY = "ERC721_LAZY";
const ERC1155_LAZY = "ERC1155_LAZY";

const createBidOrder = async (
  tokenAddress: string,
  tokenId: string,
  bidPrice: string,
  daoAddress: string,
  client: Axios
): Promise<
  { bidEncodedOrder: EncodedOrder; rawOrder: EncodedOrder } | undefined
> => {
  const take = await getDirectBuyTake(tokenAddress, tokenId, client);

  if (!take) {
    return undefined;
  }

  const order: RaribleOrder = {
    type: "RARIBLE_V2",
    maker: daoAddress,
    make: {
      value: bidPrice,
      assetType: {
        assetClass: "ERC20",
        contract: WETH9Address,
      },
    },
    take,
    data: {
      dataType: "RARIBLE_V2_DATA_V1",
      payouts: [],
      originFees: [],
    },
    salt: "3621",
    signature: " ",
  };

  return { bidEncodedOrder: await rarible.encodeOrder(order), rawOrder: order };
};

async function getDirectBuyTake(
  tokenAddress: string,
  tokenId: string,
  client: Axios
): Promise<RaribleOrderTake | undefined> {
  let assetClass, supply, uri, creators, royalties, signatures;

  try {
    //getting nft data
    const collectionRes = await client.get(`/nft/collections/${tokenAddress}`);
    const collectionData = collectionRes.data;
    const collectionType = collectionData.type;

    const apiResults = await client.get(
      `/nft/items/${tokenAddress}:${tokenId}`
    );

    if (apiResults.data.lazySupply > 0) {
      // building take struct for lazy NFT's
      const lazyNftData = (
        await client.get(`/nft/items/${tokenAddress}:${tokenId}/lazy`)
      ).data;

      if (collectionType === ERC721) {
        assetClass = ERC721LAZY;
      } else {
        assetClass = ERC1155_LAZY;
        supply = lazyNftData.supply;
      }

      assetClass = lazyNftData["@type"] === ERC721 ? ERC721LAZY : ERC1155_LAZY;
      uri = lazyNftData.uri;
      creators = lazyNftData.creators;
      royalties = lazyNftData.royalties;
      signatures = lazyNftData.signatures;
    } else {
      assetClass = collectionType;
      creators = apiResults.data.creators;
      royalties = apiResults.data.royalties;
    }

    return {
      value: "1",
      valueDecimal: 1,
      assetType: {
        assetClass,
        supply,
        uri,
        creators,
        royalties,
        signatures,
        contract: tokenAddress,
        tokenId,
      },
    };
  } catch (e) {
    await logError("NFT is not supported with error");
    await logError(e);
    return undefined;
  }
}

export default createBidOrder;
