import { render } from "react-dom";
import "./styles.css";
import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";
import { Integrations } from "@sentry/tracing";
import App from "./pages/App";
import { IS_PROD, IS_STAGING } from "./consts";

if (IS_PROD || IS_STAGING) {
  Sentry.init({
    dsn: "https://9d327eab0e1140369a8e4389bce93bec@o1119062.ingest.sentry.io/6153338",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.3,
    environment: process.env.REACT_APP_ENV,
  });
}

if (IS_PROD) {
  LogRocket.init("4aytep/static-app-prod");
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra("sessionURL", sessionURL);
    });
  });
}

render(<App />, document.getElementById("root"));
