import { Box } from "@mui/material";

const Divider = () => {
  return (
    <Box
      sx={{
        borderTop: "2px solid #ADCCCC",
        my: 0.5,
      }}
    />
  );
};

export default Divider;
