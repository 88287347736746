import { useLocation } from "react-router-dom";
import { useMemo } from "react";

function useQueryParams() {
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  return {
    ...params,
    get: (name) => params.get(name) || "",
  };
}

export default useQueryParams;
