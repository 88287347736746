import { Typography, Stack } from "@mui/material";
import { BigNumber } from "bignumber.js";
import { FC } from "react";
import { Proposal } from "../../../types/common";
import { UseProposalInfoResp } from "../../../hooks/useUserProposalInfo";
import { ethWeb3 } from "../../../magic";
import Divider from "./Divider";
import FundsRow from "./FundsRow";

type Props = {
  proposal?: Proposal;
  proposalInfo: UseProposalInfoResp;
  isProposer?: boolean;
};

const PaymentBreakdown: FC<Props> = ({
  proposal,
  proposalInfo,
  isProposer,
}) => {
  const {
    userBid,
    userRaribleFeeEth,
    userGasSurcharge,
    userContribution,
    freeBalance,
  } = proposalInfo;

  return (
    <>
      {" "}
      <Stack gap={1}>
        <FundsRow
          isBold
          amount={proposal?.proposedValue}
          text="Total group purchase"
        />
        <Divider />
        {isProposer && (
          <FundsRow
            helperText={`The bid initiator will be covering the gas cost of deploying the
        group offer to the marketplace.`}
            amount={proposal?.deployCost}
            text="Estimated gas fee for deployment"
          />
        )}
        <FundsRow
          isBold
          amount={new BigNumber(userBid)
            .plus(
              ethWeb3.utils.fromWei(String(proposal?.voterAmountOwedToMulteez))
            )
            .toString()}
          text="Personal Contribution"
        />
        <Divider />
      </Stack>
      <Typography fontWeight={900} mt={3}>
        Funds To Add
      </Typography>
      <FundsRow amount={userContribution} text="Your contribution" />
      <FundsRow
        amount={userGasSurcharge}
        helperText={
          "A gas surcharge is added to make sure you have sufficient funds for gas for transferring funds from your Multeez wallet to the DAO that holds the group's NFT. If you are the bid initiator, this also includes gas fees for deploying the DAO and for making the purchase on Rarible "
        }
        text="Gas surcharge"
      />
      {proposal?.isDirectBuy && (
        <FundsRow
          amount={userRaribleFeeEth}
          text="Rarible fee"
          helperText={
            "Rarible's service fees is added and paid for equally by all the bid contributors"
          }
        />
      )}
      <FundsRow
        isAvailable
        amount={freeBalance?.toString()}
        text="Available funds"
      />
      <Divider />
      <FundsRow
        isBold
        isTotal
        amount={proposalInfo.ethAmountToAdd.toString()}
        text="Total amount to add"
      />
    </>
  );
};

export default PaymentBreakdown;
