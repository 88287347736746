import { BigNumber } from "bignumber.js";
import Swal from "sweetalert2";
import { AddFundsPrams } from "../types";
import { logInfo, logError } from "../../../utils/logger";
import { getUserMissingFundsFromDao } from "../utils";
import { ethWeb3 } from "../../../magic";
import { ADD_FUNDS_GAS_UNITS } from "../../../utils";
import saveTransaction from "../../../apis/multeez/saveTransaction";
import { TransactionOriginAction } from "../../../hooks/useCheckIfTransactionSent";

export async function addFundsToDao({
  hash,
  sig,
  userAddress,
  userBid,
  userBidBeforeMulteezFeeBN,
  dao,
  gasUnitPrice,
  order,
  orderForBuyAddFunds,
  proposal,
  userId,
  setIsDeployInProgress,
}: AddFundsPrams) {
  //checking if member exists in dao and if so updating his add funds
  await logInfo(
    "inside add funds (transfer funds to dao) method with user address : " +
      userAddress
  );

  const memberContribution = await dao.methods
    .getMemberContribution(userAddress)
    .call();

  const userBidBN = new BigNumber(userBid);

  const missingFundsFromDao = await getUserMissingFundsFromDao(
    dao,
    userAddress,
    ethWeb3.utils.fromWei(userBidBeforeMulteezFeeBN.toString()).toString()
  );
  await logInfo(`remaining funds ${missingFundsFromDao.toString()}`);

  if (missingFundsFromDao.isGreaterThan("0")) {
    const remainingFunds = userBidBN.minus(memberContribution);
    const remainingFudsBeforMulteez =
      userBidBeforeMulteezFeeBN.minus(memberContribution);
    await logInfo(`Transferring ${remainingFunds.toString()}  Wei`);
    await logInfo(`member contribution is ${remainingFunds.toString()}`);
    await logInfo(`gas unit price ${gasUnitPrice}`);
    await logInfo(`add funds gas units ${ADD_FUNDS_GAS_UNITS}`);
    await logInfo(
      `user amount is ${await ethWeb3.eth.getBalance(userAddress)}`
    );
    const addFundsResp =
      proposal.isDirectBuy && orderForBuyAddFunds
        ? dao.methods.directBuyAddFunds(
            hash,
            sig,
            remainingFudsBeforMulteez.toString(),
            orderForBuyAddFunds.buyerOrder,
            orderForBuyAddFunds.buySignature,
            orderForBuyAddFunds.sellerOrder,
            orderForBuyAddFunds.sellSignature
          )
        : dao.methods.addFunds(hash, sig, remainingFudsBeforMulteez.toString());

    addFundsResp
      .send({
        from: userAddress,
        value: remainingFunds.toString(),
        gasPrice: gasUnitPrice,
        gas: ADD_FUNDS_GAS_UNITS,
      })
      .on("transactionHash", (transactionHash) =>
        saveTransaction({
          transactionHash,
          userId,
          proposal,
          originAction: TransactionOriginAction.AddFundsToDao,
          transactionOrder: order,
          onComplete: () => setIsDeployInProgress(false),
        })
      )
      .on("error", (error) => {
        logError("Failed to create transfer to DAO with erorr");
        logError(String(error));
        Swal.fire(
          "Oops! Something went wrong",
          error.message?.split(":")[0],
          "error"
        );
      });
  } else {
    setIsDeployInProgress(false);
    await logInfo("funds already added");
    Swal.fire({
      title: "Funds have already been added! You can close the page",
      icon: "success",
      showConfirmButton: false,
    });
    return true;
  }
}
