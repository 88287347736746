import { useEffect, useState } from "react";
import { BigNumber } from "bignumber.js";
import { ethWeb3 } from "../magic";
import { GAS_PRICE_GUARD_MULTIPLIER } from "../utils";

const useCurrentGasPrice = () => {
  const [gasUnitPrice, setGasUnitPrice] = useState("0");

  useEffect(() => {
    ethWeb3.eth.getGasPrice().then((price) => {
      const gasPriceAfterMul = new BigNumber(price)
        .multipliedBy(GAS_PRICE_GUARD_MULTIPLIER)
        .toFixed(0);
      setGasUnitPrice(gasPriceAfterMul);
    });
  }, []);

  return gasUnitPrice;
};

export default useCurrentGasPrice;
