import { Magic } from "magic-sdk";
import Web3 from "web3";

const networkName = process.env.REACT_APP_ETHEREUM_NETWORK_NAME || ""; // as EthNetworkName;

if (networkName !== "mainnet" && networkName !== "rinkeby") {
  throw new Error("Unexpected Ethereum network");
}

export const magicEthereum = new Magic(
  process.env.REACT_APP_MAGIC_PUBLISHABLE_KEY || "",
  { network: networkName }
);

magicEthereum.network = networkName;

export const ethWeb3 = new Web3(magicEthereum.rpcProvider);
