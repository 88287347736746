import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { toFixed } from "../../../utils/common";

type Props = {
  totalBalance: string | number;
  freeBalance: string | number;
  lockedFunds: string | number;
};

const Balances: FC<Props> = ({ lockedFunds, totalBalance, freeBalance }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        height: 95,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          width: 175,
          borderRadius: 3,
          padding: "3px 15px",
          background:
            "linear-gradient(180deg, rgba(255, 255, 255, 0) 3.12%, #FFFFFF 99.99%, #FFFFFF 100%)",
          filter: "drop-shadow(8px 8px 20px rgba(95, 81, 236, 0.06))",
        }}
      >
        <Typography variant="caption" color="#5F51EC">
          Total balance
        </Typography>
        <Typography
          fontWeight={300}
          align="right"
          component="span"
          variant="h4"
        >
          {toFixed(totalBalance)}
          <Typography variant="caption" ml={1} color="#5F51EC" fontWeight={300}>
            ETH
          </Typography>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 95,
          lineHeight: 1.3,
        }}
      >
        <Box>
          <Typography display="inline-block" variant="caption" color="#5F51EC">
            Free funds
          </Typography>
          <Typography
            fontWeight={300}
            align="right"
            component="span"
            variant="body"
          >
            {toFixed(freeBalance || 0)}
            <Typography
              variant="caption"
              ml={1}
              color="#5F51EC"
              fontWeight={300}
            >
              ETH
            </Typography>
          </Typography>
        </Box>
        <Box>
          <Typography display="inline-block" variant="caption" color="#5F51EC">
            Locked funds
          </Typography>
          <Typography
            fontWeight={300}
            align="right"
            component="span"
            variant="body"
          >
            {toFixed(lockedFunds)}
            <Typography
              variant="caption"
              ml={1}
              color="#5F51EC"
              fontWeight={300}
            >
              ETH
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Balances;
