import { useRef } from "react";
import Slider from "react-slick";
import { Typography } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as S from "./Onboarding.styles";
import imgSrc1 from "./images/onboarding1.png";
import imgSrc2 from "./images/onboarding2.png";
import imgSrc3 from "./images/onboarding3.png";
import imgSrc4 from "./images/onboarding4.png";
import Button from "components/Button";
import Page from "components/Page";

const sliderSettings = {
  dots: true,
  infinite: false,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Onboarding = () => {
  const sliderRef = useRef<Slider>(null);

  const nextSlide = () => {
    sliderRef.current?.slickNext();
  };

  return (
    <Page showLogo>
      <Slider ref={sliderRef} {...sliderSettings}>
        <>
          <S.Container>
            <S.TitleBox>
              <S.Title variant="h4">
                Great! <br /> You&apos;re all set.
              </S.Title>
            </S.TitleBox>
            <S.Image src={imgSrc1} alt="Onboarding slide" />
            <S.TextBox>
              <S.Text variant="body1">
                Here&apos;s what&apos;s waiting <br /> for you inside
                <S.ArrowIcon />
              </S.Text>
            </S.TextBox>
            <Button
              backgroundColor="#ffffff"
              color="#000000"
              boxShadow="9px 9px 26px rgba(95, 81, 236, 0.1)"
              width="260px"
              onClick={nextSlide}
            >
              <Typography variant="body" fontWeight={700}>
                Get Started
              </Typography>
            </Button>
          </S.Container>
        </>

        <>
          <S.Container>
            <S.TitleBox>
              <S.Title variant="h4">/Buy</S.Title>
            </S.TitleBox>
            <S.Image src={imgSrc2} alt="Onboarding slide" />
            <S.TextBox>
              <S.Text>
                Discover NFTs at Rarible.com and use <strong>/buy</strong> to
                initiate a group purchase. Or <strong>/explore</strong> existing
                proposals
              </S.Text>
            </S.TextBox>
            <Button
              backgroundColor="#ffffff"
              color="#000000"
              boxShadow="9px 9px 26px rgba(95, 81, 236, 0.1)"
              width="260px"
              onClick={nextSlide}
            >
              <Typography variant="body" fontWeight={700}>
                Next
              </Typography>
            </Button>
          </S.Container>
        </>

        <>
          <S.Container>
            <S.TitleBox>
              <S.Title variant="h4">/Import</S.Title>
            </S.TitleBox>
            <S.Image src={imgSrc3} alt="Onboarding slide" />
            <S.TextBox>
              <S.Text>
                Want to sell a portion of an NFT you already own? Simply{" "}
                <strong>/import</strong> and propose it to the group.
              </S.Text>
            </S.TextBox>
            <Button
              backgroundColor="#ffffff"
              color="#000000"
              boxShadow="9px 9px 26px rgba(95, 81, 236, 0.1)"
              width="260px"
              onClick={nextSlide}
            >
              <Typography variant="body" fontWeight={700}>
                Next
              </Typography>
            </Button>
          </S.Container>
        </>

        <>
          <S.Container>
            <S.TitleBox>
              <S.Title variant="h4">Let&apos;s go!</S.Title>
            </S.TitleBox>
            <S.Image src={imgSrc4} alt="Onboarding slide" />
            <S.TextBox>
              <S.Text>
                Get back to your Telegram group and start owning NFTs together!
              </S.Text>
            </S.TextBox>
          </S.Container>
        </>
      </Slider>
    </Page>
  );
};

export default Onboarding;
