import { useCallback, useState } from "react";
import { BigNumber } from "bignumber.js";
import { Box } from "@mui/material";
import { ethWeb3 } from "magic";
import {
  calcMissingFundsForActionWei,
  METHOD_GAS_LIMIT,
  runWithRetries,
  createContractInstance,
} from "utils";
import AddGasModal from "components/AddGasModal";
import Page from "components/Page";
import Button from "components/Button";
import Input from "components/Input";
import Loading from "components/Loading";
import { toFixed } from "utils/common";
import { logError, logInfo } from "utils/logger";
import useUserData from "hooks/useUserData";
import useCurrentGasPrice from "hooks/useCurrentGasPrice";
import saveTransaction from "apis/multeez/saveTransaction";
import useCheckIfTransactionSent, {
  TransactionOriginAction,
} from "hooks/useCheckIfTransactionSent";

export default function Withdraw() {
  const { data: userData } = useUserData();
  const gasUnitPrice = useCurrentGasPrice();

  const [disableButton, setDisableButton] = useState(false);
  const [buttonName, setButtonName] = useState("Create Withdraw Transaction");
  const [showAddGasModal, setShowAddGasModal] = useState(false);
  const [gasNeeded, setGasNeeded] = useState("0");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [withdrawAddress, setWithdrawAddress] = useState("0");

  useCheckIfTransactionSent({
    user: userData,
    originAction: TransactionOriginAction.Withdraw,
  });

  const handleConfirm = async () => confirm();

  const confirm = async () => {
    if (!userData) {
      return;
    }

    try {
      setDisableButton(true);
      setButtonName("Processing Withdraw Request...");

      const accounts = await ethWeb3.eth.getAccounts();
      let userAddress;

      if (withdrawAddress === "magic") {
        userAddress = accounts[0];
      } else {
        userAddress = withdrawAddress;
      }

      const daoAddress = urlParams.get("daoAddress") || "";

      const userMagicAccount = accounts[0];

      const howMuchGasNeeded = await calcMissingFundsForActionWei({
        gasUnitPrice,
        user: userData,
        gasUnits: METHOD_GAS_LIMIT,
      });

      if (!howMuchGasNeeded.isEqualTo("0")) {
        setGasNeeded(howMuchGasNeeded.toString());
        await logInfo(
          `to low balance for request with additional needed is ${howMuchGasNeeded.toString()}`
        );
        setShowAddGasModal(true);
        return;
      }

      const dao = await runWithRetries(createContractInstance, daoAddress);

      if (!dao) {
        await logError(
          "DAO instance failed to be created user needs to try again later, no money was spent"
        );
        alert(
          "DAO instance failed to be created please try again later, no money was spent"
        );
        return "";
      }

      const params: MemberWithdrawSendParams = {
        userAddress,
        userMagicAccount,
        dao,
        daoAddress,
        gasUnitPrice,
        userId: userData?.id || "",
        onComplete: () => setDisableButton(true),
      };

      await runWithRetries(memberWithdrawSend, params);

      setButtonName("Success!");
    } catch (e) {
      setButtonName("Failed to withdraw funds");
      await logError(e);
    }
  };

  const handleInputOnChange = useCallback((event) => {
    if (event.target.value) {
      setWithdrawAddress(event.target.value);
    } else {
      setWithdrawAddress("magic");
    }
  }, []);

  const shouldDisable = () => !userData;

  return (
    <Page>
      {!userData && <Loading text="Getting things ready..." />}
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        {showAddGasModal && (
          <AddGasModal
            open={showAddGasModal}
            onClose={() => setShowAddGasModal(false)}
            gasPriceInWei={gasNeeded}
            onFundsAdded={confirm}
            userEmail={userData?.email}
          />
        )}
        <Box mt={10} />
        <Input
          type="text"
          variant="standard"
          label="Address to transfer funds"
          placeholder="Address to transfer funds"
          onChange={handleInputOnChange}
        />
        <p>
          Estimated Gas Price:{" "}
          <strong>
            {toFixed(
              new BigNumber(
                ethWeb3.utils.fromWei(gasUnitPrice.toString())
              ).multipliedBy(String(METHOD_GAS_LIMIT))
            )}{" "}
            ETH
          </strong>
        </p>
        <Box mt={13} />
        <Button
          disabled={disableButton || shouldDisable()}
          onClick={handleConfirm}
        >
          {buttonName}
        </Button>
      </Box>
    </Page>
  );
}

type MemberWithdrawSendParams = {
  userAddress: string;
  userMagicAccount: string;
  dao: any;
  daoAddress: string;
  gasUnitPrice: string;
  userId: string;
  onComplete: () => any;
};

async function memberWithdrawSend({
  userAddress,
  userMagicAccount,
  dao,
  daoAddress,
  gasUnitPrice,
  userId,
  onComplete,
}: MemberWithdrawSendParams) {
  const memberWithdrawStatus = await dao.methods
    .memberWithdraw(userAddress)
    .send({
      from: userMagicAccount,
      gasPrice: gasUnitPrice,
      gas: METHOD_GAS_LIMIT,
    })
    .on("transactionHash", (transactionHash) =>
      saveTransaction({
        transactionHash,
        originAction: TransactionOriginAction.Withdraw,
        userId,
        daoAddress,
        onComplete,
      })
    );
  await logInfo("Member Withdraw Status: ");
  await logInfo(JSON.stringify(memberWithdrawStatus));
  return memberWithdrawStatus;
}
