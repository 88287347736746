import Swal from "sweetalert2";
import { useState } from "react";
import BigNumber from "bignumber.js";
import { useInterval } from "../../../utils/common";
import { ethWeb3 } from "../../../magic";
import getFreeUserBalance from "../../../utils/getFreeUserBalance";
import { logError, logInfo } from "../../../utils/logger";
import { postAuthRequest } from "../../../utils/auth";
import { Proposal, ProposalStatus } from "../../../types/common";

const lockUserFunds = async (userEmail: string, proposalId: string) => {
  const userId = `USER_${userEmail}`;

  try {
    await logInfo("Firing lock funds!");
    const endpoint = `${process.env.REACT_APP_CLIENT_API_ENDPOINT}/api/v0/lockfunds/${proposalId}/${userId}`;
    const res = await postAuthRequest(endpoint);
    await logInfo(JSON.stringify(res));
  } catch (e) {
    logError("Error in firing locking funds with error");
    logError(e);
  }
};

type FreeBalanceIntervalParams = {
  originalBalance: string;
  isTransactionInProgress: boolean;
  publicAddress: string;
  userEmail: string;
  proposal?: Proposal;
  proposalInfoLoading: boolean;
  userBid: number;
  setIsTransactionInProgress: (isInProg: boolean) => void;
  setFreeBalance: (bal: number) => void;
  setDisplayedBalance: (bal: string) => void;
  fullUserBidEth: number;
};

export default async function ({
  originalBalance,
  publicAddress,
  userEmail,
  setFreeBalance,
  proposal,
  setIsTransactionInProgress,
  isTransactionInProgress,
  setDisplayedBalance,
  fullUserBidEth,
}: FreeBalanceIntervalParams) {
  const [lockSent, setLockSent] = useState(false);
  const [successModalHasBeenShown, setSuccessModalHasBeenShown] =
    useState(false);

  useInterval(() => {
    if (
      !originalBalance ||
      !proposal ||
      !isTransactionInProgress ||
      !fullUserBidEth ||
      !userEmail ||
      !publicAddress
    ) {
      return;
    }

    const runEffect = async () => {
      const balance = ethWeb3.utils.fromWei(
        await ethWeb3.eth.getBalance(publicAddress)
      );
      const freeBalanceEth = await getFreeUserBalance(userEmail, publicAddress);
      setDisplayedBalance(balance);
      setFreeBalance(freeBalanceEth);

      const ethAmountToAddBN = new BigNumber(fullUserBidEth);
      const freeBalanceEthBN = new BigNumber(freeBalanceEth).plus(0.00001);
      logInfo(`free balance to check against ${freeBalanceEthBN.toFixed(5)}`);

      if (
        ethAmountToAddBN.isGreaterThanOrEqualTo(freeBalanceEthBN.toFixed(5))
      ) {
        return;
      }

      if (
        !lockSent &&
        proposal.proposalStatus === ProposalStatus.PendingFunding
      ) {
        setLockSent(true); // make sure we only lock once!
        await lockUserFunds(userEmail, proposal.id);
      }

      setIsTransactionInProgress(false);

      if (!successModalHasBeenShown) {
        Swal.fire({
          title: "Hooray! Funds have been added!",
          text: `Funds have been added to your Multeez wallet.
                    You may now close the page and head back to Telegram`,
          icon: "success",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        setSuccessModalHasBeenShown(true);
      }
    };

    runEffect();
  }, 1000 * 5);
}
