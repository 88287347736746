import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { magicEthereum } from "../magic";
import Loading from "../components/Loading";

export default function Callback() {
  const navigate = useNavigate();

  useEffect(() => {
    // On mount, we try to login with a Magic credential in the URL query.
    magicEthereum.auth.loginWithCredential().finally(() => {
      navigate("/");
    });
  }, []);

  return <Loading />;
}
