import { BigNumber } from "bignumber.js";
import { ethWeb3 } from "../magic";
import { getAuthRequest } from "./auth";
import { logInfo } from "./logger";

export default async function getFreeUserBalance(
  userEmail: string,
  publicAddress: string
): Promise<number> {
  const userId = `USER_${userEmail}`;
  const userRoute = `${process.env.REACT_APP_CLIENT_API_ENDPOINT}/api/v0/user/${userId}`;
  const response = await getAuthRequest(userRoute);

  if (response.status !== 200) {
    return 0;
  }

  let balance = await ethWeb3.eth.getBalance(publicAddress);
  balance = ethWeb3.utils.fromWei(balance);
  const freeBalance = Number(
    new BigNumber(balance).minus(response.data.lockedFunds).toString()
  );
  await logInfo(`user balance: ${String(balance)}`);
  await logInfo(`user free balance: ${String(freeBalance)}`);
  return freeBalance;
}
