import { FC, useEffect, useState, useCallback } from "react";
import { BigNumber } from "bignumber.js";
import { Helmet } from "react-helmet";
import { Box, Typography } from "@mui/material";
import AddFundsButton from "../../components/AddFundsButton";
import Loading from "../../components/Loading";
import Page from "../../components/Page";
import useProposalData from "../../hooks/useProposalData";
import getFreeUserBalance from "../../utils/getFreeUserBalance";
import { wyreTransfer } from "../../utils/wyreTransfer";
import {
  metamaskTransfer,
  isMetaMaskInstalled,
} from "../../utils/metamaskTransfer";
import useUserData from "../../hooks/useUserData";
import useQueryParams from "../../hooks/useQueryParams";
import { useUserProposalInfo } from "../../hooks/useUserProposalInfo";
import useGetFreeBalanceInterval from "./hooks/useFreeBalanceInterval";
import Balances from "./components/Balances";
import PaymentBreakdown from "./components/PaymentBreakdown";

const AddFunds: FC = () => {
  const { data: userData } = useUserData();
  const { magicWallet: publicAddress = "", email: userEmail = "" } =
    userData || {};
  const queryParams = useQueryParams();
  const { data: proposal } = useProposalData(queryParams.get("proposalId"));

  const [isTransactionInProgress, setIsTransactionInProgress] = useState(false);
  const [displayedBalance, setDisplayedBalance] = useState("0");

  const proposalInfo = useUserProposalInfo({
    proposal,
    user: userData,
    setDisplayedBalance,
  });

  useEffect(() => {
    getFreeUserBalance(userEmail || "", publicAddress).then((bal) =>
      proposalInfo.setFreeBalance(bal)
    );
  }, []);

  useGetFreeBalanceInterval({
    isTransactionInProgress,
    setFreeBalance: proposalInfo.setFreeBalance,
    userEmail,
    proposalInfoLoading: proposalInfo.isSetupInProgress,
    userBid: proposalInfo.userBid,
    publicAddress,
    originalBalance: proposalInfo.originalBalance,
    setDisplayedBalance,
    proposal,
    setIsTransactionInProgress,
    fullUserBidEth: proposalInfo.fullUserBidEth,
  });

  const handleMetamaskTransfer = useCallback(
    () =>
      metamaskTransfer({
        userEmail,
        publicAddress,
        amountToSendInEth: String(proposalInfo.ethAmountToAdd),
        setEthAmountToAdd: proposalInfo.setEthAmountToAdd,
        setIsTransactionInProgress,
      }),
    [publicAddress, proposalInfo.ethAmountToAdd, userEmail]
  );

  const handleWyreTransfer = useCallback(
    () =>
      wyreTransfer({
        userEmail,
        setEthAmountToAdd: proposalInfo.setEthAmountToAdd,
        ethAmountToAdd: proposalInfo.ethAmountToAdd,
        setIsTransactionInProgress,
      }),
    [proposalInfo.ethAmountToAdd, userEmail]
  );

  return (
    <Page>
      <Helmet>
        <script
          src={`https://verify.${
            process.env.REACT_APP_WYRE_ENVIRONMENT === "test" ? "test" : "send"
          }wyre.com/js/verify-module-init-beta.js`}
        />
      </Helmet>
      {proposalInfo.isSetupInProgress && (
        <Loading text="Setting things up, please wait..." />
      )}
      {isTransactionInProgress && (
        <Loading text="Transaction in progress, please wait..." />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6" fontWeight={900}>
          Multeez Wallet
        </Typography>

        <Balances
          freeBalance={proposalInfo.freeBalance}
          lockedFunds={userData?.lockedFunds || "0"}
          totalBalance={displayedBalance}
        />

        <Box
          margin="16px -30px 0"
          bgcolor="rgba(255, 255, 255, 0.7)"
          px={3}
          borderRadius={6}
          position="relative"
        >
          <Box sx={{ display: "flex", mt: 3 }}>
            <Box
              component="img"
              sx={{
                height: 44,
                width: 44,
                borderRadius: 1,
                objectFit: "cover",
                mr: 2,
              }}
              alt={proposal?.nftName}
              src={proposal?.imageUrl}
            />
            <Box>
              <Typography fontWeight={900}>{proposal?.nftName}</Typography>
              <Typography>
                {new BigNumber(1)
                  .dividedBy(proposal?.slots || 1)
                  .multipliedBy(100)
                  .toFixed(2)}
                % Ownership*
              </Typography>
            </Box>
          </Box>
          <Box mt={2} />

          <PaymentBreakdown
            proposal={proposal}
            proposalInfo={proposalInfo}
            isProposer={true}
          />

          <Box mt={2} gap={2} display={"flex"} flexDirection={"column"}>
            <AddFundsButton
              provider="metamask"
              onClick={handleMetamaskTransfer}
              disabled={!isMetaMaskInstalled()}
            />
            <AddFundsButton provider="wyre" onClick={handleWyreTransfer} />
            <Typography variant="caption">
              *Subject to gas fee fluctuations upon deployment of the bid.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default AddFunds;
