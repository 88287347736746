import { useCallback, useState } from "react";
import Swal from "sweetalert2";
import { Proposal, User } from "../../../types/common";
import { RARIBLE_BUY_GAS_UNITS, getDaoIfExists } from "../../../utils";
import saveTransaction from "../../../apis/multeez/saveTransaction";
import { TransactionOriginAction } from "../../../hooks/useCheckIfTransactionSent";
import { DaoAbi } from "../../../types/web3-v1-contracts/daoAbi";
import { logError } from "../../../utils/logger";

type UseBuyNftParams = {
  proposal?: Proposal;
  user?: User;
};

type UseBuyNftResp = {
  buyNftLoading: boolean;
  buyNft: () => void;
};

const useBuyNft = ({ proposal, user }: UseBuyNftParams): UseBuyNftResp => {
  const [buyNftLoading, setBuyNftLoading] = useState(false);

  const buyNft = useCallback(async () => {
    if (!proposal?.isDirectBuy || !user) {
      return;
    }

    const dao: DaoAbi = await getDaoIfExists(proposal);
    setBuyNftLoading(true);
    dao.methods
      .directBuyFromRarible()
      .send({
        from: user.magicWallet,
        gas: RARIBLE_BUY_GAS_UNITS,
      })
      .on("transactionHash", (transactionHash) =>
        saveTransaction({
          transactionHash,
          userId: user.id,
          proposal,
          originAction: TransactionOriginAction.DirectBuy,
          onComplete: () => setBuyNftLoading(false),
        })
      )
      .on("error", (error) => {
        logError("Failed to create direct buy");
        Swal.fire(
          "Oops! Something went wrong",
          error.message?.split(":")[0],
          "error"
        );
      });
  }, [proposal, user]);

  return { buyNft, buyNftLoading };
};

export default useBuyNft;
