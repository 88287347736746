import Swal from "sweetalert2";
import { TransactionOriginAction } from "../../hooks/useCheckIfTransactionSent";
import { postAuthRequest } from "../../utils/auth";
import { Proposal } from "../../types/common";
import { RaribleOrder } from "../rarible/types";
import { logInfo } from "../../utils/logger";

const baseUrl = process.env.REACT_APP_CLIENT_API_ENDPOINT;

type CreateTransactionParams = {
  transactionHash: string;
  proposal?: Proposal;
  daoAddress?: string;
  buyoutSecondUserId?: string;
  userId: string;
  originAction: TransactionOriginAction;
  transactionOrder?: RaribleOrder;
  onComplete: () => any;
};

const saveTransaction = async ({
  transactionHash,
  proposal,
  userId,
  originAction,
  buyoutSecondUserId,
  transactionOrder,
  onComplete,
  daoAddress,
}: CreateTransactionParams) => {
  logInfo(
    `transaction hash ${transactionHash} from user ${userId} and proposal id ${proposal?.id} with origin action ${originAction} and dao address ${daoAddress}`
  );
  const transactionResp = await postAuthRequest(
    `${baseUrl}/api/v0/transaction`,
    {
      transactionHash,
      proposalId: proposal?.id,
      proposalTime: proposal?.proposalTime,
      buyoutSecondUserId,
      userId,
      originAction,
      daoAddress,
      transactionOrder,
    }
  );

  if (transactionResp.status === 200) {
    onComplete();
    Swal.fire({
      title: "Transaction Sent!",
      text: "Transaction has been submitted you will receive a confirmation message in telegram upon completion",
      icon: "success",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/wallet";
      }
    });
  }
};

export default saveTransaction;
