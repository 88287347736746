import { Axios } from "axios";
import { RaribleOrder, RaribleOrderMake } from "../types";

async function createDirectSellOrder(
  tokenAddress: string,
  tokenId: string,
  bidPrice: string,
  client: Axios
): Promise<RaribleOrder | undefined> {
  const make = await getDirectSellMake(tokenAddress, tokenId, client);

  if (!make) {
    return undefined;
  }

  return {
    make,
    take: {
      value: bidPrice,
      assetType: {
        assetClass: "ETH",
      },
    },
    type: "RARIBLE_V2",
    maker: "",
    data: {
      dataType: "RARIBLE_V2_DATA_V1",
      payouts: [],
      originFees: [],
    },
    salt: "3621",
    signature: " ",
  };
}

/*
returns -1 if can't find the asset type else returns the take structure
*/
async function getDirectSellMake(
  tokenAddress: string,
  tokenId: string,
  client: Axios
): Promise<RaribleOrderMake | undefined> {
  try {
    const collectionRes = await client.get(`/nft/collections/${tokenAddress}`);

    const itemRes = await client.get(`/nft/items/${tokenAddress}:${tokenId}`);

    return {
      value: "1",
      valueDecimal: 1,
      assetType: {
        contract: tokenAddress,
        tokenId,
        assetClass: collectionRes.data.type,
        creators: itemRes.data.creators,
        royalties: itemRes.data.royalties,
      },
    };
  } catch (e) {
    console.log("NFT is not supported");
    return undefined;
  }
}

export default createDirectSellOrder;
