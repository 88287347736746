import { useQuery } from "react-query";
import { User } from "../types/common";
import { getAuthRequest } from "../utils/auth";

const baseUrl = process.env.REACT_APP_CLIENT_API_ENDPOINT;

const fetchUserData = async (): Promise<User> => {
  const { data } = await getAuthRequest(`${baseUrl}/api/v0/user`);
  return data;
};

const useUserData = () => {
  return useQuery<User>(["userData"], fetchUserData);
};

export default useUserData;
