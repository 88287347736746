import { FC, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import metamaskSrc from "assets/metamask.png";
import wyreSrc from "assets/wyre.png";
import { ReactComponent as Caret } from "assets/caret-full.svg";
import { ReactComponent as CreditCard } from "assets/credit-card.svg";
import { ReactComponent as CreditCardWhite } from "assets/credit-card-white.svg";

type Props = {
  provider: "metamask" | "wyre";
} & LoadingButtonProps;

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "8px 8px 20px rgba(95, 81, 236, 0.1)",
  textTransform: "none",
  backgroundColor: "#ffffff",
  borderRadius: 12,
  height: 67,
  width: "100%",
  color: "#000000",
  paddingLeft: theme.spacing(2),
  "&:hover": {
    backgroundColor: "#5F51EC",
    color: "#ffffff",
  },
  "&:active": {
    backgroundColor: "#5F51EC",
    color: "#ffffff",
  },
}));

const CustomButton: FC<Props> = ({ provider, ...props }) => {
  const [isHoveringBtn, setIsHoveringBtn] = useState(false);

  if (provider === "metamask") {
    return (
      <StyledButton fullWidth size="large" variant="contained" {...props}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              width: 30,
              height: 30,
            }}
            src={metamaskSrc}
            alt="Metamask"
          />
          <Typography fontWeight="bold" ml={3}>
            Metamask
          </Typography>
        </Box>
        <Caret />
      </StyledButton>
    );
  }

  if (provider === "wyre") {
    return (
      <StyledButton
        onMouseEnter={() => setIsHoveringBtn(true)}
        onMouseLeave={() => setIsHoveringBtn(false)}
        fullWidth
        size="large"
        variant="contained"
        {...props}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {isHoveringBtn ? (
            <CreditCardWhite width={50} />
          ) : (
            <CreditCard width={50} />
          )}
          <Typography fontWeight="bold" ml={1}>
            Credit Card
          </Typography>
          <Typography mx={1}>via</Typography>
          <img width={47} height={16} src={wyreSrc} />
        </Box>
        <Caret />
      </StyledButton>
    );
  }

  return null;
};

export default CustomButton;
