import { FC, useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { BigNumber } from "bignumber.js";
import { Box, Modal, Typography, Fade, Backdrop } from "@mui/material";
import Input from "../Input";
import Loading from "../Loading";
import AddFundsButton from "../AddFundsButton";
import { useInterval } from "utils/common";
import { ethWeb3 } from "magic";
import { logInfo } from "utils/logger";
import useAuth from "context/useAuth";
import getFreeUserBalance from "utils/getFreeUserBalance";
import { wyreTransfer } from "utils/wyreTransfer";
import { metamaskTransfer, isMetaMaskInstalled } from "utils/metamaskTransfer";

type Props = {
  open: boolean;
  userEmail?: string;
  onClose: () => void;
  gasPriceInWei: string;
  onFundsAdded: () => void;
};

const AddGasModal: FC<Props> = ({
  open,
  onClose,
  gasPriceInWei,
  onFundsAdded,
  userEmail = "",
}) => {
  const { magicUser } = useAuth();
  const publicAddress = magicUser?.publicAddress || "";
  const [requestedGasPriceInEth, setRequestedGasPriceInEth] = useState(0);
  const [isTransactionInProgress, setIsTransactionInProgress] = useState(false);
  const [initialFreeBalance, setInitialFreeBalance] = useState(0);

  useEffect(() => {
    if (!userEmail) {
      return;
    }

    getFreeUserBalance(userEmail, publicAddress).then((freeBalance) => {
      setInitialFreeBalance(freeBalance || 0);
    });

    const requestedGasInEth = new BigNumber(
      ethWeb3.utils.fromWei(gasPriceInWei)
    );

    setRequestedGasPriceInEth(Number(requestedGasInEth));
  }, [ethWeb3.utils, gasPriceInWei, userEmail]);

  useInterval(() => {
    // every 5 seconds check the current free balance
    getFreeUserBalance(userEmail, publicAddress).then((freeBalanceInEth) => {
      // take the minimal gas price needed
      let gasPriceWithInitialFreeBalance = new BigNumber(
        requestedGasPriceInEth
      );
      // get the free balance calculated when we entered the modal.
      const initialFreeBalanceBN = new BigNumber(initialFreeBalance);
      // the targeted amount will be the minimal needed gas + the initial free balance
      gasPriceWithInitialFreeBalance =
        gasPriceWithInitialFreeBalance.plus(initialFreeBalanceBN);
      logInfo(`freeBalanceInEth ${freeBalanceInEth} `); //to ask alex (no await)
      logInfo(`gasPriceInEth ${gasPriceWithInitialFreeBalance.toString()}`); //to ask alex (no await)

      // check if the current free balance is greater then the minimal target price.
      if (
        freeBalanceInEth &&
        new BigNumber(freeBalanceInEth).isGreaterThanOrEqualTo(
          gasPriceWithInitialFreeBalance
        )
      ) {
        console.log("🚀  sufficent funds");
        setIsTransactionInProgress(false);
        onFundsAdded();
        onClose();
      }
    });
  }, 1000 * 5);

  const handleMetamaskTransfer = useCallback(
    () =>
      metamaskTransfer({
        userEmail,
        publicAddress,
        amountToSendInEth: String(requestedGasPriceInEth),
        setEthAmountToAdd: setRequestedGasPriceInEth,
        setIsTransactionInProgress,
      }),
    [publicAddress, requestedGasPriceInEth, userEmail]
  );

  const handleWyreTransfer = useCallback(
    () =>
      wyreTransfer({
        userEmail,
        setEthAmountToAdd: setRequestedGasPriceInEth,
        ethAmountToAdd: requestedGasPriceInEth,
        setIsTransactionInProgress,
      }),
    [requestedGasPriceInEth, userEmail]
  );

  return (
    <>
      <Helmet>
        <script
          src={`https://verify.${
            process.env.REACT_APP_WYRE_ENVIRONMENT === "test" ? "test" : "send"
          }wyre.com/js/verify-module-init-beta.js`}
        ></script>
      </Helmet>
      <Modal
        sx={{ zIndex: 100 }}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#ffffff",
              width: 340,
              height: 440,
              boxShadow: 24,
            }}
          >
            {isTransactionInProgress && (
              <Loading text="Transaction in progress, please wait..." />
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                display="flex"
                alignItems="center"
                align="center"
                variant="subtitle1"
                bgcolor="#ffdfe5"
                height={84}
              >
                You don&apos;t have sufficient balance for gas to complete this
                request
              </Typography>
              <Typography variant="h5" fontWeight={600} mt={4}>
                Add Funds
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: 280,
                  mt: 3,
                }}
              >
                <Input
                  disabled
                  type="number"
                  name="amount"
                  label="Gas required"
                  variant="standard"
                  placeholder="Amount to transfer"
                  value={requestedGasPriceInEth}
                />
                <Typography component="span" ml={2}>
                  ETH
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "90%",
                  mt: 5,
                }}
              >
                <AddFundsButton
                  provider="metamask"
                  onClick={handleMetamaskTransfer}
                  disabled={!isMetaMaskInstalled()}
                />
                <Box sx={{ height: 10 }} />
                <AddFundsButton provider="wyre" onClick={handleWyreTransfer} />
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default AddGasModal;
