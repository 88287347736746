import { BigNumber } from "bignumber.js";
import _ from "lodash";
import rarible from "../client";
import { RaribleOrder, OrdersForBuyAddFunds } from "../types";
import { ethWeb3 } from "../../../magic";

BigNumber.config({ EXPONENTIAL_AT: 100 });

const createOrdersForBuyAddFunds = async ({
  tokenAddress,
  tokenId,
  daoAddress,
  directBuySig,
}): Promise<OrdersForBuyAddFunds | undefined> => {
  const nftData = await rarible.getNftData({ tokenAddress, tokenId }); // check not []

  if (nftData?.length === 0) {
    return undefined;
  }

  const sellOrder: RaribleOrder = nftData.orders.find(
    (order: RaribleOrder) =>
      order.type === "RARIBLE_V2" && order.signature === directBuySig
  );
  const salt = new BigNumber(sellOrder.salt, 16);
  // making salt to be a number in the order
  sellOrder.salt = salt.toString();
  const sellSig = sellOrder.signature;
  const sellOrderCopy = _.cloneDeep(sellOrder);
  const buyOrder = createBuyOrder(sellOrderCopy, daoAddress);
  buyOrder.make.value = ethWeb3.utils
    .toWei(String(sellOrder.makePrice))
    .toString();
  buyOrder.take.value = "1";
  console.log(JSON.stringify(sellOrder));
  const buyOrderEncoded = await rarible.encodeOrder({ ...buyOrder });
  const sellOrderEncoded = await rarible.encodeOrder({ ...sellOrder });

  const buyerOrderEncodedRaw = buyOrderEncoded.signMessage.struct;
  const sellerOrderEncodedRaw = sellOrderEncoded.signMessage.struct;

  const buyerOrder = { ...buyerOrderEncodedRaw };
  const sellerOrder = { ...sellerOrderEncodedRaw };

  return {
    buyerOrder,
    sellerOrder,
    sellSignature: sellSig,
    buySignature: "0x",
    buyOrderEncoded,
  };
};

const createBuyOrder = (
  sellOrder: RaribleOrder,
  daoAddress: string
): RaribleOrder => ({
  ...sellOrder,
  make: sellOrder.take,
  take: sellOrder.make,
  maker: daoAddress,
  taker: sellOrder.maker,
});

export default createOrdersForBuyAddFunds;
