import { useQuery } from "react-query";
import axios from "axios";
import { BigNumber } from "bignumber.js";
import { getAuthHeader } from "../utils/auth";
import { logInfo } from "utils/logger";
import { ethWeb3 } from "magic";

const baseUrl = process.env.REACT_APP_CLIENT_API_ENDPOINT;

const getFreeUserBalance = async (
  userEmail: string | undefined,
  publicAddress: string | undefined
): Promise<number> => {
  const userId = `USER_${userEmail}`;
  const { data } = await axios.get(`${baseUrl}/api/v0/user/${userId}`, {
    headers: await getAuthHeader(),
  });

  if (!publicAddress) return 0;
  let balance = await ethWeb3.eth.getBalance(publicAddress);
  balance = ethWeb3.utils.fromWei(balance);
  const freeBalance = Number(
    new BigNumber(balance).minus(data.lockedFunds).toString()
  );
  await logInfo(`user balance: ${String(balance)}`);
  await logInfo(`user free balance: ${String(freeBalance)}`);
  return freeBalance;
};

export default function (
  userEmail: string | undefined,
  publicAddress: string | undefined
) {
  return useQuery(
    ["userFreeBalance"],
    () => getFreeUserBalance(userEmail, publicAddress),
    {
      enabled: Boolean(userEmail && publicAddress),
      refetchInterval: 3000,
    }
  );
}
