import { useQuery } from "react-query";
import axios from "axios";
import { Proposal } from "../types/common";
import { getAuthHeader } from "../utils/auth";
import { logInfo } from "../utils/logger";

const baseUrl = process.env.REACT_APP_CLIENT_API_ENDPOINT;

const fetchProposalData = async (
  proposalId: string
): Promise<Proposal | undefined> => {
  const { data } = await axios.get(`${baseUrl}/api/v0/proposal/${proposalId}`, {
    headers: await getAuthHeader(),
  });

  await logInfo(`Proposal ${proposalId} fetched`);
  return data;
};

export default function (proposalId: string) {
  return useQuery<Proposal | undefined>(
    ["proposalData"],
    () => fetchProposalData(proposalId),
    {
      enabled: Boolean(proposalId),
    }
  );
}
