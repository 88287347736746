import { FC } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import Swal from "sweetalert2";
import { toFixed } from "../../../utils/common";
import { ReactComponent as InfoIcon } from "../../../assets/info.svg";

type Props = {
  amount?: string | number;
  text: string;
  isBold?: boolean;
  isTotal?: boolean;
  isAvailable?: boolean;
  helperText?: string;
};

const FundsRow: FC<Props> = ({
  text,
  amount = 0,
  isBold,
  isTotal,
  isAvailable,
  helperText,
}) => {
  const handleInfoClick = () => {
    Swal.fire({
      text: helperText,
      icon: "info",
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        display="flex"
        variant="subtitle2"
        fontWeight={isBold ? 900 : 400}
        color="#566665"
      >
        {text}
        {helperText && (
          <IconButton
            size={"small"}
            onClick={handleInfoClick}
            style={{ marginLeft: 5 }}
          >
            <InfoIcon />
          </IconButton>
        )}
      </Typography>
      <Typography
        variant="subtitle2"
        color={isTotal ? "#AB213A" : isAvailable ? "#128152" : "#566665"}
        fontWeight={isBold ? 900 : 400}
      >
        {toFixed(amount)} ETH
      </Typography>
    </Box>
  );
};

export default FundsRow;
