import Lottie from "react-lottie-player";
import { Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import animationData from "./animation.json";

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  inset: "0",
  background: "rgba(255, 255, 255, 0.9)",
  zIndex: "100",
});

const Loading = ({ text = "" }) => (
  <Container>
    <Typography textAlign={"center"} variant={"h5"}>
      {text}
    </Typography>
    <Lottie
      loop
      animationData={animationData}
      style={{ width: 150, height: 150 }}
      play
    />
  </Container>
);

export default Loading;
