import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)({
  "& label": {
    fontFamily: "Lato",
  },
  "& .MuiInputBase-input": {
    fontFamily: "Lato",
  },
  "& label.Mui-focused": {
    color: "#000000",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 0,
      borderColor: "#000000",
    },
  },
  width: "100%",
});

const Input = ({ ...props }) => {
  return <StyledTextField margin="normal" {...props} />;
};

export default Input;
