import { styled } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import { ReactComponent as Arrow } from "./images/arrow.svg";

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  outline: "none",
  marginBottom: theme.spacing(4),
}));

export const TitleBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: 78,
  margin: theme.spacing(1, 0, 4),
}));

export const Title = styled(Typography)({
  fontWeight: 900,
  lineHeight: 1.08,
  textAlign: "center",
});

export const TextBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 263,
  height: 72,
  margin: theme.spacing(2, 0, 5),
}));

export const Text = styled(Typography)({
  lineHeight: 1.5,
  textAlign: "center",
});

export const Image = styled("img")({
  width: 200,
  height: 200,
  objectFit: "cover",
});

export const ArrowIcon = styled(Arrow)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  transform: "translateY(-2px)",
}));
