import axios from "axios";
import Cookies from "js-cookie";
import { magicEthereum } from "../magic";

export const SIX_HOURS = 60 * 60 * 6;

export function invalidateToken() {
  Cookies.remove("token");
}

export function setToken(token) {
  Cookies.set("token", token);
}

const authRequest = async (
  method: "GET" | "POST",
  url: string,
  data?: Record<string, any>
) => {
  try {
    // first try
    await axios(url, {
      method,
      data,
      headers: await getAuthHeader(),
    });
  } catch (error: any) {
    if (error.response?.status === 401) {
      console.log("User unauthorized, retrying once");
      invalidateToken();
    } else {
      throw error;
    }
  }

  // second try
  return axios(url, {
    method,
    data,
    headers: await getAuthHeader(),
  });
};

export async function getAuthHeader() {
  const tokenFromCookie = Cookies.get("token");

  if (!tokenFromCookie) {
    const token = await magicEthereum.user.getIdToken({ lifespan: SIX_HOURS });
    setToken(token);
    return {
      Authorization: `Bearer ${token}`,
    };
  }

  return {
    Authorization: `Bearer ${tokenFromCookie}`,
  };
}

export async function getAuthRequest(url: string) {
  return authRequest("GET", url);
}

export async function postAuthRequest(url: string, data = {}) {
  return authRequest("POST", url, data);
}
